/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Text, HStack, Flex } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'
import { MdFilterList } from 'react-icons/md'
import CustomMenu from './../menu/index'
import { sectionDetailedTrack } from '../../containers/repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../../containers/repository/repo.utils'
import {
  GridComponent, ColumnsDirective,
  ColumnDirective, Inject, Filter,
  Sort, ExcelExport, Toolbar
} from '@syncfusion/ej2-react-grids';
import { PropagateLoader } from 'react-spinners'
import '../dashboard-table/table.scss'
let sortIcon = sortType =>
  sortType === 'asc' ? (
    <IoMdArrowDropup size={16} />
  ) : sortType === 'desc' ? (
    <IoMdArrowDropdown size={16} />
  ) : null

export function TH({
  rowSpan,
  colSpan,
  header,
  children,
  sortField,
  sortRecords,
  sortType,
  timelineFilter,
  selectedCheckbox,
  setSelectedCheckbox,
}) {
  const [border, setBorder] = useState('0')

  useEffect(() => {
    if (selectedCheckbox?.hasOwnProperty(header?.db_field_name)) {
      setBorder('1px')
    } else {
      setBorder('0')
    }
  }, [selectedCheckbox])

  return (
    <TD rowSpan={rowSpan} colSpan={colSpan}>
      <HStack>
        <Box id="title">
          <Text
            onClick={() => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DASHBOARD,
                action: 'DrillDownTable Grid',
                label: 'Sort Records',
              })
              sortRecords(header?.db_field_name)
            }}
            color="white"
          >
            {children}
          </Text>
        </Box>
        <Box>
          <span style={{ position: 'absolute' }}>
            {sortField === header?.db_field_name ? sortIcon(sortType) : null}
          </span>
        </Box>
        {timelineFilter && (
          <Box pl={2}>
            <CustomMenu
              optionList={Object.values(timelineFilter)?.map(item => item)}
              selected={
                selectedCheckbox?.[header?.db_field_name] &&
                Object.values(selectedCheckbox?.[header?.db_field_name])?.map(
                  item => item
                )
              }
              isCheckBox
              onSelect={(item, flag) => {
                let columnName = header?.db_field_name
                let newDropdownData = {}
                newDropdownData = {
                  ...selectedCheckbox,
                }
                if (flag) {
                  delete newDropdownData[columnName][item.label]
                  if (Object.keys(newDropdownData[columnName]).length === 0) {
                    // setBorder('0');
                    delete newDropdownData[columnName]
                  }
                } else {
                  newDropdownData[columnName]
                    ? (newDropdownData[columnName][item.label] = item)
                    : (newDropdownData[columnName] = {
                      [item.label]: item,
                    })
                  // setBorder('1px');
                }
                setSelectedCheckbox(newDropdownData)
              }}
              icon={MdFilterList}
              color="white"
              bg="none"
              size={12}
              _focus={{
                bg: 'secondary',
                color: localStorage.getItem('color'),
              }}
              _hover={{
                bg: 'secondary',
                color: localStorage.getItem('color'),
              }}
              border={border}
            />
          </Box>
        )}
      </HStack>
    </TD>
  )
}

TH.defaultProps = {
  sortRecords: () => { },
}

export function TD({ rowSpan, colSpan, children, onClickHandler, classes }) {
  return (
    <td rowSpan={rowSpan} colSpan={colSpan} className={classes}>
      <span
        onClick={e => {
          sectionDetailedTrack({
            category: GA_CATEGORY_DASHBOARD,
            action: 'DrillDownTable Grid',
            label: 'Table Data Clicked',
          })
          if (onClickHandler) onClickHandler(e)
        }}
      >
        {children}
      </span>
    </td>
  )
}

export function TR({ children }) {
  return <tr style={{ whiteSpace: 'break-spaces' }}>{children}</tr>
}


const DrillDownTable = ({ isLoading = false, tableHeaderList = [], tableData = [], onCellClicked, allowSorting = true, allowFiltering = true }) => {

  let grid;

  const filterSettings = { type: 'CheckBox' };
  const selectionSettings = { type: 'Single', mode: 'Cell' };
  const toolbar = ['ExcelExport'];
  const toolbarClick = (args) => {
    if (grid && args.item.id === 'grid_excelexport') {
      grid.excelExport();
    }
  };

  const cellSelecting = (args) => {
    let column = grid.getColumnByIndex(args.cellIndex.cellIndex).field;
    if (column === 'activity_title') {
      onCellClicked(args)
    }
  }

  const queryCellInfo = (args) => {
    if (args.column.field === 'activity_title') {
      args.cell.style.color = args.data.color// timeColor(args.data.activity_datetime_end_deferred);
    }
    if(!!args.data[`${args.column.field}_rag_status_name`]) {
      args.cell.classList.add(args.data[`${args.column.field}_rag_status_name`])
    }
  }


  const customAttributes = { class: 'customcss' };
  return (
    <div className="drilldowntable">
      {isLoading ? (
        <Flex my="15px" justify="left" ml={[200]}>
          <PropagateLoader color="#718096" />
        </Flex>
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ width: 'auto' }}
        >
          <GridComponent
            dataSource={tableData}
            ref={g => grid = g}
            id="grid"
            allowSorting={allowSorting}
            allowFiltering={allowFiltering}
            toolbar={toolbar}
            allowExcelExport={true}
            toolbarClick={toolbarClick}
            allowTextWrap={true}
            filterSettings={filterSettings}
            enableStickyHeader={true}
            cellSelecting={cellSelecting.bind(this)}
            selectionSettings={selectionSettings}
            queryCellInfo={queryCellInfo.bind(this)}
          >
            <ColumnsDirective>
              {/* <ColumnDirective headerText="Sl No" width='100'></ColumnDirective>  */}
              {
                !!tableHeaderList.length && tableHeaderList.map((val, i) => (
                  <ColumnDirective key={i}
                    field={val.db_field_name}
                    headerText={val.header_name}
                    width={val?.width || '150'}
                    customAttributes={val.db_field_name === 'activity_title' ? customAttributes : ''}
                  //    columns={[{ field: 'OrderDate', headerText: 'Order Date', format: 'yMd', width: 130, textAlign: 'Right' }, { field: 'Freight', headerText: 'Freight ($)', width: 120, format: 'C1', textAlign: 'Right' }]} 
                  ></ColumnDirective>
                ))
              }
            </ColumnsDirective>
            <Inject services={[Sort, Filter, ExcelExport, Toolbar]} />
          </GridComponent>
        </div>
      )}
    </div>
  )
}

export default DrillDownTable

DrillDownTable.defaultProps = {
  theadings: [],
  tdata: [],
  thLength: 9,
}
