import React from 'react'
import Countdown from 'react-countdown'

const CountdownTimer = ({ sessionTime, triggerLogoutPopup }) => {
	// Renderer callback with condition
	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			triggerLogoutPopup()
		} else {
			// Render a countdown
			return (
				<span>
					{hours < 10 ? ` 0${hours}` : hours}:
					{minutes < 10 ? ` 0${minutes}` : minutes}:
					{seconds < 10 ? ` 0${seconds}` : seconds}
				</span>
			)
		}
	}

	return (
		<Countdown date={Date.now() + sessionTime * 1000} renderer={renderer} />
	)
}

export default CountdownTimer
