/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Text,
	HStack,
	VStack,
	Flex,
	Grid,
	GridItem,
	Skeleton,
	Button,
	Heading,
	useDisclosure,
} from '@chakra-ui/react'
import { BsImageAlt } from 'react-icons/bs'
import React, { useEffect, useState } from 'react'
import CardDetails from './card-details'
import { loadImageBlob } from '../../utils/aws.util'

const encode = data => {
	let str = data.reduce(function (a, b) {
		return a + String.fromCharCode(b)
	}, '')
	return btoa(str).replace(/.{76}(?=.)/g, '$&\n')
}

const GridFlexData = ({ j, title, color, image, item, handleCardData }) => {
	const [imageData, setImageData] = useState(null)
	useEffect(() => {
		setTimeout(() => {
			if (!!image) {
				loadImageBlob(image, (data, type) => {
					let imgdata = 'data:' + type + ';base64,' + encode(data)
					setImageData(imgdata)
				})
			}
		}, 1000)
	}, [image])
	const maxLength = 50
	const truncatedTitle =
		title.length > maxLength ? `${title.slice(0, maxLength).trim()}...` : title
	return (
		<GridItem
			key={`item${j}`}
			colSpan={3}
			cursor={'pointer'}
			onClick={() => handleCardData(item, imageData)}
		>
			<Box
				p={5}
				shadow='md'
				borderWidth='1px'
				key={j}
				minH='250px'
				bg={'white'}
			>
				<VStack w={'full'} justifyContent={'center'}>
					<HStack
						p={5}
						w={'full'}
						justifyContent={'center'}
						className='card-img'
					>
						{!imageData ? (
							<BsImageAlt size={100} color={'gray'} />
						) : (
							<img src={imageData} alt={'Dashboard'} width={150} />
						)}
					</HStack>
					<Heading color={color} size='sm'>
						{truncatedTitle}
					</Heading>
				</VStack>
			</Box>
		</GridItem>
	)
}

const DrillDownCard = ({ theadings, tdata, isLoading }) => {
	const { isOpen, onClose, onOpen } = useDisclosure()
	const [cardData, setCardData] = useState({})
	const [img, setImg] = useState('')
	const handleCardData = (data, img) => {
		setCardData(data)
		setImg(img)
		onOpen()
	}
	return (
		<>
			<div className='drilldownCard'>
				{tdata.map((grid, i) => {
					return !Array.isArray(grid) ? (
						<GridItem key={i} colSpan={3}>
							<Skeleton key={i} minH='200px' />
						</GridItem>
					) : (
						<Grid
							key={i}
							h='100%'
							w='100%'
							templateColumns='repeat(12, 1fr)'
							gap={3}
						>
							{grid?.map((item, j) => {
								let title = item
									.filter(f => f.db_field_name === 'activity_title')
									.reduce((a, s) => s.value, '')
								let a_image = item?.[0]?.['activity_image_path']
								let a_color = item?.[0]?.['color']
								return (
									<GridFlexData
										key={`c${j}`}
										j={j}
										item={item}
										title={title}
										color={a_color}
										image={a_image}
										handleCardData={handleCardData}
									/>
								)
							})}
						</Grid>
					)
				})}
			</div>
			{isOpen ? (
				<CardDetails
					card_data={cardData}
					image={img}
					isOpen={isOpen}
					onClose={onClose}
				/>
			) : null}
		</>
	)
}

export default DrillDownCard

DrillDownCard.defaultProps = {
	theadings: [],
	tdata: [],
	thLength: 9,
}
