import { Button, Text } from '@chakra-ui/react'
import { useState } from 'react'

export const ExpandLink = ({ data, textOnClick }) => {
	const [isExpandedLink, setIsExpandedLink] = useState(false)

	const toggleReadMoreLink = event => {
		event.stopPropagation()
		setIsExpandedLink(!isExpandedLink)
	}
	return (
		<Text
			style={
				data?.db_field_name === 'activity_title'
					? {
							fontWeight: '600',
							cursor: 'pointer',
							color: 'black',
					  }
					: null
			}
			onClick={textOnClick}
		>
			{isExpandedLink ? data?.value : data?.value?.toString().slice(0, 50)}

			{data?.value?.length > 50 && (
				<Button
					alignSelf='flex-start'
					cursor='pointer'
					fontSize='sm'
					display='inline'
					color='blue.500'
					ml={1}
					onClick={toggleReadMoreLink}
				>
					{isExpandedLink ? 'read less' : 'read more'}
				</Button>
			)}
		</Text>
	)
}
