/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Button,
	Center,
	Divider,
	Flex,
	Heading,
	HStack,
	Icon,
	IconButton,
	List,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Spinner,
	Text,
	VStack,
} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import { useLanguageContext } from '../../context/locale.context'
import { BsImageAlt } from 'react-icons/bs'
import { downloadAwsFile } from '../../utils/aws.util'
import { hasImageOrVideoUrl, validURL } from '../../utils/common.util'
import { ExpandLink } from './expandLink'

const ActivityImage = ({ url }) => {
	return <img src={url} alt={'Dashboard'} width={300} height={300} />
}

const CardDetails = ({ card_data, image, isOpen, onClose }) => {
	const [loader, setLoader] = useState(false)
	const inputRef = useRef()
	const {
		state: { locale },
	} = useLanguageContext()
	let title = card_data
		.filter(f => f.db_field_name === 'activity_title')
		.reduce((a, s) => s.value, '')
	let a_image = image
	// const hasImageOrVideoUrl = str => {
	// 	var urlPattern =
	// 		/(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg|mp4|avi|mov)/gi
	// 	var matches = str && str.match(urlPattern)
	// 	return matches !== null && matches?.length > 0
	// }
	const [isExpandedLink, setIsExpandedLink] = useState(false)
	const toggleReadMoreLink = event => {
		event.stopPropagation()
		setIsExpandedLink(!isExpandedLink)
	}

	return (
		<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent borderRadius='md' bg='white' minW='70vw' m='auto' h='600px'>
				<ModalCloseButton />
				<ModalBody p={0} w='100%'>
					<HStack h='full'>
						<Box h='full' w='40%' overflow='auto'>
							<VStack
								py={2}
								h='full'
								justifyContent='center'
								alignItems='center'
							>
								<Heading
									size='md'
									ml={2}
									color='brand.800'
									fontWeight='400'
									mb={3}
									textAlign={'center'}
								>
									{title}
								</Heading>
								<Flex
									w='80%'
									h='60%'
									border='2px'
									borderColor='brand.800'
									borderStyle='dashed'
									borderRadius='10px'
									cursor='pointer'
								>
									<VStack
										justifyContent='center'
										w='100%'
										alignItems='center'
										spacing={3}
										className='card-model-img'
									>
										{!a_image ? (
											<Icon
												as={BsImageAlt}
												w={20}
												h={20}
												color='brand.800'
												strokeWidth='1px'
											/>
										) : (
											<ActivityImage url={a_image} />
										)}
									</VStack>
								</Flex>
							</VStack>
						</Box>
						<VStack
							h='full'
							borderRightRadius='md'
							flex={1}
							bg='secondary'
							display='flex'
						>
							<VStack w='100%' mx='auto' flex={1} mt={10} alignItems='center'>
								<List
									w='full'
									spacing={3}
									h='496px'
									overflowX={'hidden'}
									overflowY='auto'
								>
									{(card_data || []).map((d, index) => {
										//let checkIsUrl = hasImageOrVideoUrl(d?.value)
										let checkIsUrl = false
										let hyperLink = false
										let isMultiple = false
										let multiValue = []
										if (
											d?.value &&
											d?.value?.toString().includes('file_name') &&
											d?.value?.toString().includes('file_url')
										) {
											const jsonArray = JSON.parse(d?.value)
											isMultiple = true
											multiValue = jsonArray
										} else if (!!d?.value && typeof d?.value === 'string') {
											let url = validURL(d?.value)
											if (
												url &&
												d?.value.toString().includes('ltts-worlddesk')
											) {
												checkIsUrl =
													!!d?.value && typeof d?.value === 'string'
														? hasImageOrVideoUrl(d?.value)
														: ''
											} else if (url && hasImageOrVideoUrl(d?.value)) {
												checkIsUrl = true
											} else if (url && d?.value.startsWith('http')) {
												hyperLink = true
											}
										}

										return (
											<>
												<ListItem key={index}>
													<Flex ml={5} spacing={2}>
														<Box ml={2} flex={1}>
															<Text fontSize='16px' fontWeight={'800'}>
																{d.header_name}
															</Text>

															{checkIsUrl ? (
																<Text
																	onClick={() =>
																		d.db_field_name === 'activity_title'
																			? [
																					d?.onClickHandler(d.listItem),
																					setLoader(true),
																			  ]
																			: checkIsUrl
																			? downloadAwsFile(d.value)
																			: null
																	}
																	fontSize='14px'
																	fontWeight={
																		d.db_field_name === 'activity_title' ||
																		checkIsUrl
																			? '800'
																			: 'normal'
																	}
																	cursor={
																		d.db_field_name === 'activity_title' ||
																		checkIsUrl
																			? loader
																				? 'wait'
																				: 'pointer'
																			: 'default'
																	}
																	color={
																		d.db_field_name === 'activity_title' ||
																		checkIsUrl
																			? 'brand.800'
																			: 'inherit'
																	}
																	whiteSpace='pre-line'
																>
																	{checkIsUrl
																		? d.value?.split('/').pop()
																		: d.value}
																</Text>
															) : isMultiple ? (
																multiValue?.map((item, index) => (
																	<React.Fragment key={index}>
																		<Text
																			onClick={() =>
																				(checkIsUrl = hasImageOrVideoUrl(
																					item?.file_url
																				)
																					? downloadAwsFile(item?.file_url)
																					: null)
																			}
																			fontSize='14px'
																			fontWeight={
																				d.db_field_name === 'activity_title' ||
																				isMultiple
																					? '800'
																					: 'normal'
																			}
																			cursor={
																				d.db_field_name === 'activity_title' ||
																				isMultiple
																					? loader
																						? 'wait'
																						: 'pointer'
																					: 'default'
																			}
																			color={
																				d.db_field_name === 'activity_title' ||
																				isMultiple
																					? 'brand.800'
																					: 'inherit'
																			}
																			whiteSpace='pre-line'
																		>
																			{item?.file_url?.split('/').pop()}
																		</Text>
																	</React.Fragment>
																))
															) : (
																<>
																	<ExpandLink
																		data={d}
																		textOnClick={() => {
																			if (
																				d?.db_field_name === 'activity_title'
																			) {
																				d?.onClickHandler(d.listItem)
																				setLoader(true)
																			}
																		}}
																	/>
																</>
															)}
														</Box>
													</Flex>
												</ListItem>
												<Divider border='1px' borderColor='white' mt={2} />
											</>
										)
									})}
								</List>
							</VStack>
							<ModalFooter
								w='full'
								py={2}
								alignSelf='flex-end'
								justifySelf='flex-end'
							>
								<Button
									variant='outline'
									colorScheme={localStorage.getItem('color')}
									mr={3}
									onClick={e => {
										onClose(e)
									}}
									borderRadius='md'
									boxShadow='md'
									size='md'
								>
									{locale['Cancel']}
								</Button>
							</ModalFooter>
						</VStack>
					</HStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default CardDetails
