/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Text, HStack, Flex } from '@chakra-ui/react'
import React, { useState, useEffect, forwardRef } from 'react'
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'
import { MdFilterList } from 'react-icons/md'
import './table.scss'
import CustomMenu from './../menu/index'
import { sectionDetailedTrack } from '../../containers/repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../../containers/repository/repo.utils'
import { PropagateLoader } from 'react-spinners'

let sortIcon = sortType =>
	sortType === 'asc' ? (
		<IoMdArrowDropup size={16} />
	) : sortType === 'desc' ? (
		<IoMdArrowDropdown size={16} />
	) : null

export function TH({
	rowSpan,
	colSpan,
	header,
	children,
	sortField,
	sortRecords,
	sortType,
	timelineFilter,
	selectedCheckbox,
	setSelectedCheckbox,
	classes,
	justify = '',
	minWidth,
	setSortFieldName,
}) {
	const [border, setBorder] = useState('0')

	useEffect(() => {
		if (selectedCheckbox?.hasOwnProperty(header?.db_field_name)) {
			setBorder('1px')
		} else {
			setBorder('0')
		}
	}, [selectedCheckbox])

	return (
		<TD
			rowSpan={rowSpan}
			colSpan={colSpan}
			classes={classes}
			style={{ backgroundColor: localStorage.getItem('color') }}
		>
			<HStack justify={justify} style={{ minWidth: minWidth }}>
				<Box
					id='title'
					style={
						header?.db_field_name === 'activity_title'
							? { width: '250px' }
							: { width: '100px' }
					}
				>
					<Text
						onClick={() => {
							sectionDetailedTrack({
								category: GA_CATEGORY_DASHBOARD,
								action: 'DrillDownTable',
								label: 'Sort Records',
							})
							sortRecords(header?.db_field_name)
							setSortFieldName(header?.db_field_name)
							localStorage.setItem('sortFieldName', header?.db_field_name)
						}}
						color='white'
					>
						{children}
					</Text>
				</Box>
				<Box>
					<span style={{ position: 'absolute' }}>
						{sortField === header?.db_field_name ? sortIcon(sortType) : null}
					</span>
				</Box>
				{timelineFilter && (
					<Box pl={2}>
						<CustomMenu
							optionList={Object.values(timelineFilter)?.map(item => item)}
							selected={
								selectedCheckbox?.[header?.db_field_name] &&
								Object.values(selectedCheckbox?.[header?.db_field_name])?.map(
									item => item
								)
							}
							isCheckBox
							closeOnSelect={false}
							onSelect={(item, flag) => {
								let columnName = header?.db_field_name
								let newDropdownData = {}
								newDropdownData = {
									...selectedCheckbox,
								}
								if (flag) {
									delete newDropdownData[columnName][item.label]
									if (Object.keys(newDropdownData[columnName]).length === 0) {
										// setBorder('0');
										delete newDropdownData[columnName]
									}
								} else {
									newDropdownData[columnName]
										? (newDropdownData[columnName][item.label] = item)
										: (newDropdownData[columnName] = {
												[item.label]: item,
										  })
									// setBorder('1px');
								}
								setSelectedCheckbox(newDropdownData)
							}}
							icon={MdFilterList}
							color='white'
							bg='none'
							size={12}
							_focus={{
								bg: 'secondary',
								color: localStorage.getItem('color'),
							}}
							_hover={{
								bg: 'secondary',
								color: localStorage.getItem('color'),
							}}
							border={border}
							menuItemTextProps={{ maxW: '40vw', noOfLines: 2 }}
						/>
					</Box>
				)}
			</HStack>
		</TD>
	)
}

TH.defaultProps = {
	sortRecords: () => {},
}

export function TD({
	rowSpan,
	colSpan,
	children,
	onClickHandler,
	classes,
	style = {},
}) {
	return (
		<td rowSpan={rowSpan} colSpan={colSpan} className={classes} style={style}>
			<span
				onClick={e => {
					sectionDetailedTrack({
						category: GA_CATEGORY_DASHBOARD,
						action: 'DrillDownTable',
						label: 'Table Data Clicked',
					})
					if (onClickHandler) onClickHandler(e)
				}}
			>
				{children}
			</span>
		</td>
	)
}

export const TR = forwardRef(({ children, classes, tabIndexRequired }, ref) => {
	return (
		<tr
			style={{ whiteSpace: 'break-spaces' }}
			className={classes}
			ref={ref}
			tabIndex={tabIndexRequired ? 1 : null}
		>
			{children}
		</tr>
	)
})

const DrillDownTable = ({ theadings, tdata, isLoading }) => {
	return (
		<div className='drilldowntable'>
			<table>
				<thead>{theadings}</thead>
				<tbody>
					{tdata?.length && Array.isArray(tdata) ? (
						tdata.map(data => data)
					) : (
						<TR key='no_record'>
							<TD colSpan={100}>
								{isLoading ? (
									<Flex my='15px' justify='left' ml={[200]}>
										<PropagateLoader color='#718096' />
									</Flex>
								) : (
									<Text color='#48BB78' fontSize='2xl' align='left' ml={[200]}>
										No Records Found
									</Text>
								)}
							</TD>
						</TR>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default DrillDownTable

DrillDownTable.defaultProps = {
	theadings: [],
	tdata: [],
	thLength: 9,
}
